<template>
	<div class="header" :class="themes=='white'?'white':''">
		<div class="left">
			<a  class="logoLink">
				<img class="logo" :src="appLogo">
				<!-- <span class="name">{{appName}}</span> -->
			</a>
			<div class="nav">
				<ul class="navList">
					<li class="navItem" :class="{select:currentIndex==0}" @click="select(0)">{{$t('webHeader.home')}}</li>
					<li class="navItem" :class="{select:currentIndex==1}" @click="select(1)">{{$t('webHeader.leveragedTrading')}}</li>
					<li class="navItem" :class="{select:currentIndex==2}" @click="select(2)">{{$t('webHeader.optionsTrading')}}</li>
					<li class="navItem" :class="{select:currentIndex==5}" @click="select(5)">{{$t('lockup.nav_title')}}</li>
					<li class="navItem" >{{$t('webHeader.appDownload')}}
						<!-- <div class="xiazai">
							<img class="qrcode" src="@/assets/web/img/qrcode.png"/>
							<div class="text">{{$t('webHeader.appDownload')}}</div>
						</div> -->
					</li>
					<li class="navItem" :class="{select:currentIndex==4}" @click="select(4)">{{$t('webHeader.helpCenter')}}</li>
				</ul>
			</div>
		</div>
		<div class="right">
			<ul>
				<li class="item" @click="login()" v-if="!token">{{$t('webHeader.login')}}</li>
				<li class="item" @click="register()" v-if="!token">{{$t('webHeader.register')}}</li>
				<li class="item" @click="personCenter()" v-if="token" ><span class="default" :class="{select:currentIndex==5}">{{$t('webHeader.personCenter')}}</span></li>
				<li class="item" v-if="token" @click="assets()"><span class="default" :class="{select:currentIndex==6}">{{$t('webHeader.assets')}}</span>
					<!-- <i class="downTriangle"></i><i class="upTriangle"></i>
					<div class="dropDown">
						<ul>
							<li v-for="(item,index) in AssetsOption" :key="item.value" @click="onAssets(item)">{{item.text}}</li>
						</ul>
					</div> -->
				</li>
				<li class="item" @click="deposit()" v-if="token"><span class="default" :class="{select:currentIndex==7}">{{$t('webHeader.deposit')}}</span>
				</li>
				<li class="item" @click="withdraw()" v-if="token"><span class="default" :class="{select:currentIndex==8}">{{$t('webHeader.withdraw')}}</span></li>
				<li class="item" @click="quit()" v-if="token">{{$t('webHeader.quit')}}</li>
				<li class="item">{{yuyan}}<i class="downTriangle"></i><i class="upTriangle"></i>
					<div class="dropDown">
						<ul>
							<li v-for="(item,index) in option" :key="item.value" @click="onLanguage(item)">{{item.text}}</li>
						</ul>
					</div>
				</li>
				<li class="item" @click="goServer()">{{$t('login.serverurl')}}</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			currentIndex:{
				type:Number,
				default:''
			},
			themes:{
				type:String,
				default:'dark',
			}
		},
		data(){
			return {
				appLogo:'',
				appName:'',
				option: [
				  { text: "English", value: "en" },
				  { text: "繁体中文", value: "ru" }, //繁体中文
				  { text: "اللغة العربية", value: "zh" },//阿拉伯语
				  { text: "日本語", value: "fr" },
				  { text: "Tiếng Việt", value: "it" }, //越南语
				  { text: "Español", value: "es" }, //西班牙语
				],
				AssetsOption: [
				  { text: this.$t('webHeader.otcAssets'), value: "1" },
				  { text: this.$t('webHeader.leveragedAssets'), value: "2" }, 
				  { text: this.$t('webHeader.leveragedAssets'), value: "3" },
				],
				yuyan:'English',
				lang:localStorage.getItem('lang'),
				token:localStorage.getItem('token'),
				serverurl:'',
			}
		},
		mounted(){
			if(this.lang){
				this.option.forEach((item)=>{
					if(item.value == this.lang){
						this.yuyan = item.text;
					}
				})
			}
			// if(localStorage.getItem('appLogo')){
			// 	this.appLogo = localStorage.getItem("appLogo");
			// 	this.appName = localStorage.getItem("appName");
			// }else{
				this.getLogo();
			// }
			// if(localStorage.getItem('serverurl')){
			// 	this.serverurl = localStorage.getItem("serverurl");
			// }else{
				this.getserver();
			// }
			
		},
		methods:{
			select(index){
				this.currentIndex = index;
				switch (index){
					case 0:
					this.$router.push('/web/index/index');
					break;
					case 1:
					this.$router.push('/web/trade/LeveragedTrading');
					break;
					case 2:
					this.$router.push('/web/trade/FuturesTrading');
					break;
					case 5:
					this.$router.push('/web/trade/LockUpMining');
					break;
					case 3:
					this.$router.push('/web/index/index');
					break;
					case 4:
					this.$router.push('/web/helpCenter/index');
					break;
				}
			},
			login(){
				this.$router.push('/web/index/login')
			},
			register(){
				this.$router.push('/web/index/register')
			},
			async getLogo() {
			  const { data } = await this.$http.get("/home/index/getConf");
			  if (data) {
			    if (data.code === 200) {
			      this.appLogo = data.data.imageurl;
			      this.appName = data.data.sitename;
				  localStorage.setItem("appLogo",this.appLogo)
				  localStorage.setItem("appName",this.appName)
			    }
			  }
			},
			onLanguage(item) {
			  let lang = item.value;
			  this.yuyan = item.text;
			  this.$i18n.locale = lang;
			  localStorage.setItem("lang", lang);
			  localStorage.setItem("language", lang);
			  //location.reload();
			},
			personCenter(){
				this.$router.push("/web/my/index")
			},
			// 获取客服链接
			async getserver() {
			  const { data } = await this.$http.get("/home/index/serviceurl");
			  if (data) {
			    if (data.code === 200) {
			      this.serverurl = data.data;
				  localStorage.setItem("serverurl", this.serverurl);
			    }
			  }
			},
			// 客服页面
			goServer() {
			  console.log("this.serverurl",this.serverurl)
			  location.href = this.serverurl;
			},
			quit(){	
				localStorage.removeItem("token");
				this.$router.push("/web/index/login")
			},
			assets(){
				this.$router.push({
					path:"/web/assets/index",
					query:{id:'1',}
				})
			},
			deposit(){
				this.$router.push({
					path:"/web/assets/index",
					query:{id:'4',}
				})
			},
			withdraw(){
				this.$router.push({
					path:"/web/assets/index",
					query:{id:'3',}
				})
			},
		}
	}
</script>

<style lang="less" scoped>
.header{ position: relative;  height:60px; background-color: #141416;border-bottom: 1px solid #23262f; display: flex; justify-content: space-between;  padding:0 20px; min-width:1200px;
	.left{ max-width: 900px;  white-space: nowrap;
		.logoLink{ padding-top: 2.5px;  margin-right: 10px;display: inline-block; 
			.logo{ height:55px; display: inline-block; vertical-align: middle; margin-right: 10px;}
			.name{ color:#FFF; font-size:40px; font-weight: 300; display: inline-block; vertical-align: middle;}
		}
		.nav{display: inline-block; vertical-align: middle;
			.navList{ font-size: 14px; margin-top:-4px;
				.navItem{ display: inline-block; padding:0 20px; cursor: pointer; color:#fff; height:60px; line-height: 60px; position: relative;
					&.select{ color: #fff;border-bottom: 2px solid #fff; font-weight: 700;}
					.xiazai{display: none; position: absolute;top: 59px;left: 50%;transform: translateX(-50%);padding: 12px;background: #1b1c20;z-index: 1;
						.qrcode{ width: 100px; height:100px;}
						.text{ text-align: center; color:#fff; font-size: 12px; line-height: 14px; margin-bottom: 15px;}
					}
					&:hover{color: #fff;
						.xiazai{ display: block;}
					}
				}
			}
		}
		
	}
	.right{max-width: 900px;  white-space: nowrap;
		.item{ display: inline-block; min-height: 60px; overflow: visible; line-height: 60px; padding:0 20px; cursor: pointer; color:#fff; font-size:14px; position: relative;
			.upTriangle{ margin:0 5px; display: none; vertical-align: middle; border-top:none; border-left:5px solid transparent; border-right:5px solid transparent; border-bottom:5px solid #fff; width:0; height:0;}
			.downTriangle{ margin:0 5px; display: inline-block; vertical-align: middle; border-bottom:none; border-left:5px solid transparent; border-right:5px solid transparent; border-top:5px solid #fff; width:0; height:0;}
			.dropDown{display: none; position: absolute; z-index: 999;top: 59px;left: 50%;transform: translateX(-50%);padding: 5px;background: #1b1c20;z-index: 1;
				li{ line-height: 46px; height:46px; min-width: 120px;
					&:hover{ background-color:#2e394e;}
				}
			}
			.default{ border:1px solid #141416; border-radius: 5px; padding:5px 10px;}
			.default.select{ border:1px solid #363636; border-radius: 5px; padding:5px 10px;}
			&:hover{
				.upTriangle{ display: inline-block;}
				.downTriangle{ display: none;}
				.dropDown{ display: block;color: rgb(174, 185, 216);background-color: rgb(20, 32, 53);}
			}
		}
		
	}
	&.white{ background-color: #FFF;border-bottom: none;
		.left{
			.logoLink{
				.name{color:#222732;}
			}
			.navList{
				.navItem{ color:#222732;margin-top: -4px;
					&.select{ color: #363636;border-bottom: 2px solid #363636; }
					&:hover{color:#363636;
					}
				}
			}
		}
		.right{
			.item{color:#222732;
				.default{ border:1px solid #fff; border-radius: 5px; padding:5px 10px;}
				.default.select{ border:1px solid #fff; border-radius: 5px; padding:5px 10px;}
			}
			.upTriangle{border-bottom:5px solid #222732;}
			.downTriangle{border-top:5px solid #222732;}
		}
	}
}
</style>